<template>
  <section class="page-container">
    <ul class="spider-body">
      <li class="spider-item">
        <div class="spider-content">
          <div style="text-align: center">
            <h3><b>番剧爬虫-lf1080.com</b></h3>
          </div>
          <el-form :inline="true" :model="spiderForm" style="padding: 10px">
            <el-form-item label="爬取目录：">
              <el-radio-group v-model="spiderForm.menuId">
                <el-radio label="28">里番</el-radio>
                <el-radio label="30">3D</el-radio>
                <el-radio label="32">MMD</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="网站总页数：">
              <el-input v-model="spiderForm.pages"></el-input>
            </el-form-item>
            <el-form-item label="网站Cookie：">
              <el-input v-model="spiderForm.cookie"></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: center;">
            <el-button type="primary" @click="lf1080Submit()">确 定</el-button>
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "spider",
  data() {
    return {
      spiderForm:{
        menuId:'',
        pages:'',
        cookie:'',
        line:''
      }
    }
  },
  methods: {
    async lf1080Submit(){
      let me = this
      try{
        me.spiderForm.line = 'lf1080'
        await me.axios.form("/api/admin/spider/animation",me.spiderForm)
        me.$notify.success({ title: "成功", message: "启动成功" });
        me.addAisle = false
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.spider-body{
  display: flex;
  flex-flow: wrap;
  align-items: stretch;
  margin-right: -16px;
  margin-bottom: -16px;
  padding: 0;
  list-style: none;
  width: 100%;
  .spider-item{
    width: 25%;
    box-sizing: border-box;
    .spider-content{
      background-color: #F5F5F5;
      height: 350px;
      position: relative;
      margin-bottom: 16px;
      margin-right: 16px;
      overflow: hidden;
      border-radius: 4px;
    }
  }
}
@media screen and (max-width: 768px) {
  .spider-body {
    .spider-item {
      width: 100%;
      .spider-content{
        height: 350px;
        margin-bottom: 16px;
        margin-right: 0;
      }
    }
  }
}
</style>
